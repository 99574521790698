import './styles/main.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Nav from './components/Nav'
import Home from './components/Home'
import About from './components/About'
import Inventory from './components/Inventory'
import Financing from './components/Financing'
import InventoryDetails from './components/InventoryDetails';

function App() {

  return (
    <Router>
      <div className="primaryContainer">
        <Nav />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/financing">
            <Financing />
          </Route>
          <Route exact path="/inventory">
            <Inventory />
          </Route>
          <Route path="/inventory/:productId">
            <InventoryDetails />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>


      <footer className="footer">

      </footer>
    </Router>
  );
}

export default App;

import Collapsible from 'react-collapsible';

const options = [
    {
        name: "TRAC Leases",
        paragraphs: [
            "Terminal Rent Adjustment Clause, a lease on vehicles intended for commercial use more than 50% of the time. Lessee has the right to buy the leased vehicles at a predetermined price at expiration of lease term. Lower down payment required, fixed monthly payment, lease payments are fully tax deductible by lessee, lessor retains the depreciation benefits.", 
        ]
    },
    {
        name: "FMV Leases",
        paragraphs: [
            "Fair Market Value leases do not have a fixed purchase option at the expiration of the lease term. Value of the equipment at the end of the lease term is the then fair market value determined by an arm’s length transaction, between a willing buyer and seller. Lessor retains ownership of the equipment."
        ]
    },
    {
        name: "Installment Leases",
        paragraphs: [
            "There is a fixed purchase option at expiration of lease term. Lessee will have the benefit of depreciation from the lease. There is no residual amount due at end of lease term. Ownership is transferred at the end of lease term."
        ]
    },
    {
        name: "Conventional Financing",
        paragraphs: [
            "Periodic payments that include interest and principal over a fixed term. Tradition may require a lien on the equipment as collateral against your debt."
        ]
    }
]

function Financing () {

    return (
        <div className="financing">
            <div className="container">
                <h1>Financing Options</h1>
                <p>Whether you are looking to buy or equipment, we will work with you on creating the best path to achieve your financial goals. We offer a wide range of financial and leasing options. Please reach out to discuss these options!</p>
            </div>
            <div className="container">
                {options.map((option, i) => (
                    <Collapsible trigger={option.name} className="collapsible" key={i}>
                        <div className="collaplible-drawer">
                            {option.paragraphs.map((par, j) => (<p key={`${i}-${j}`}>{par}</p>))}
                        </div>
                    </Collapsible>
                ))}
            </div>
            <div className="container">
                <h1>How it Works</h1>
                <p>Download the credit application below, fill it out, and send to Ryan at <a href="mailto:ryan@traditionequipment.com?subject=Credit Application">ryan@traditionequipment.com</a>.</p>
            </div>
            <div className="container">
                <a href="/BlankCreditApp.pdf" className="link-button" target="_blank">Download Here</a>
            </div>
        </div>
    )
}

export default Financing
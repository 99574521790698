import bus from '../img/bus-1.jpg'
import b2 from '../img/b2.jpg'
import b3 from '../img/b3.jpg'

import { HashLink as ScrollLink } from 'react-router-hash-link';


function Home() {
  

    return (
        <div className="home">
            <div className="hero-container">
                <div className="hero-content">
                    <h1>Commercial Equipment Finance and Leasing</h1>
                    <span>Tradition Equipment Leasing, LLC combines 30+ years’ experience in new and used equipment sales, leasing and financing nationwide. Our strong relationships are a key in working together to bring the best financing solution to our customers. Now more than ever, we realize how important it is to work closely with our customers to ensure their success.</span>
                    <ScrollLink to="#contact">Let's talk</ScrollLink>
                </div>
                {/* <img className="hero-img" src={bus} alt="example of a bus"/> */}
            </div>

            <div className="container container__services">

                <Service 
                    title="Equipment Finance and Leasing" 
                    text="Tradition Equipment Leasing, LLC provides multiple leasing and finance programs. We will work with you on figuring out the best option to achieve your financial goals."
                />
                <div className="bus-img-container">
                    <img src={bus} alt="example bus" className="bus-img" />
                </div>

            </div>

            <div className="container container__services">
                <Service
                    title="Fleet Management"
                    text="Whether you are looking to right size your fleet or add multiple units for an upcoming project, we can assist in helping to determine the next steps."
                    flip={true}
                />
                <div className="bus-img-container">
                    <img src={b2} alt="example bus" className="bus-img" />
                </div>
            </div>

            <div className="container container__services">
                <Service
                    title="New and Used Motorcoach Sales"
                    text="We help our customers in the disposition of both new and used equipment. Please see our Inventory list for what we currently have available for sale."
                />
                <div className="bus-img-container">
                    <img src={b3} alt="example bus" className="bus-img" />
                </div>

            </div>

            <div className="container container__contact">
                <div className="contact" id="contact">
                    <h1>Get in Contact With Us Today</h1>
                    
                    <div className="infos">
                        <span><b>Tradition Equipment Leasing, LLC</b></span>
                        <span>1645 Lyndale Ave N, Ste 101</span>
                        <span>Faribault, MN 55021</span>
                        <a href="mailto:ryan@traditionequipment.com?subject=Get in Contact">Email Us</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Service (props) {

    return (
        <div className="service">
            <div className={`service-content ${
                props.flip ?
                "service-content--flip":
                ""
                }`}>
                <h3>{props.title}</h3>
                <span>{props.text}</span>
            </div>
        </div>
    )
}



export default Home;

import { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"

import StoryblokClient from 'storyblok-js-client/dist/es5/index.es'

function InventoryDetails () {

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [productData, setProductData] = useState()
    const { productId } = useParams()


    useEffect(() => {
        async function getInventoryProduct() {
            setIsPageLoading(true)

            let Storyblok = new StoryblokClient({
                accessToken: 'rjrSt7TnhclwJBOnbcrnyQtt',
                cache: {
                    clear: 'auto',
                    type: 'memory'
                }
            })

            try {
                const res = await Storyblok.get(`cdn/stories/${productId}`, {})
                const data = res.data.story
                console.log(data)
                setProductData(data)
            } catch (err) {
                console.error(err)
            } finally {
                setIsPageLoading(false)
            }
        }

        getInventoryProduct()
    }, [productId])

    
    // <a href={`mailto:ryan@traditionequipment.com?subject=${data.make + ", " + data.model}`}>Inquire about this item</a>
    return (
        <div className="inventory-details">
            {
                isPageLoading ? 
                <div>Loading..</div> :
                !productData ? 
                <>
                    <div className='container'>
                        <Link className='goBack' to='/inventory'>Go Back</Link>
                    </div>
                    <div className='container'>
                        <h3>Something went wrong while trying to load the product...</h3>
                    </div>
                </> :
                <>
                    <div className='container'>
                        <Link className='goBack' to='/inventory'>Go Back</Link>
                    </div>
                        <div className='container container__no-bottom'>
                        <h1 className='title'>{productData.content.title || "N/A"}</h1>
                    </div>
                    <div className='container container__half'>
                        <div className='image-container'>
                            <img className='title-image'
                                src={productData.content.image}
                                alt={productData.content.title} />
                        </div>
                    </div>
                    <div className='container container__half'>
                                <h3>{productData.content.title}</h3>
                    </div>
                </>
            }

        </div>
    )
}


export default InventoryDetails
import ryan from '../img/ryan.jpg'
import duane from '../img/duane.jpg'


function About () {

    return (
        <div className="About">
            <div className="container">
                <h1>What We Do</h1>
                <h3>Leasing Programs</h3>
                <ul>
                    <li><b>TRAC</b> – Terminal Rent Adjustment Clause, a lease on vehicles intended for commercial use more than 50% of the time. Lessee has the right to buy the leased vehicles at a predetermined price at expiration of lease term. Lower down payment required, fixed monthly payment, lease payments are fully tax deductible by lessee, lessor retains the depreciation benefits.</li>
                    <li><b>FMV</b> – Fair Market Value leases do not have a fixed purchase option at the expiration of the lease term. Value of the equipment at the end of the lease term is the then fair market value determined by an arm’s length transaction, between a willing buyer and seller. Lessor retains ownership of the equipment.</li>
                    <li><b>Installment</b> – There is a fixed purchase option at expiration of lease term. Lessee will have the benefit of depreciation from the lease. There is no residual amount due at end of lease term. Ownership is transferred at the end of lease term.</li>
                </ul>
                <h3>Conventional Financing</h3>
                <p>Periodic payments that include interest and principal over a fixed term. Tradition may require a lien on the equipment as collateral against your debt.</p>
                <h3>Fleet Management</h3>
                <p>Buy/Sell Inventory – Tradition will work with you on brokering buses for sale. We will also look at purchasing new and preowned equipment.</p>
            </div>
            <div className="container">
                <h1>Meet the Team</h1>
                <TeamMember
                    name="Ryan Geiger"
                    image={ryan}
                    title="President, CEO"
                    email="ryan@traditionequipment.com"
                    emailName="Ryan" />
                <TeamMember
                    name="Duane Geiger"
                    image={duane}
                    title="Vice President/COO"
                    email="duane@traditionequipment.com"
                    emailName="Duane" />
                
                <br />
                <br />
                    
            </div>
            <div className="container">
                <h3>Ryan Geiger – President/CEO</h3>
                <p>Ryan is leading Tradition Equipment Leasing, LLC in its goal to be a company with the skills, capital and creativity to meet the ever-changing business conditions in the US marketplace. Ryan has 10 years’ experience in a variety of successful positions that have helped build an understanding and appreciation of valuable relationships.</p>
                <p>Ryan is a graduate of Saint John’s University with a Bachelor of Arts degree in Finance. In his free time, Ryan enjoys spending time with his wife, child, and dog. He also enjoys playing competitive amateur golf, coaching youth basketball, and spending time in northern Minnesota at the lake.</p>
            </div>

            <div className="container">
                <h3>Duane Geiger – Vice President/COO</h3>
                <p>Duane has combined the valuable knowledge acquired as a CPA in public accounting and over 30 years’ experience in sales, leasing and financing to help in his role with Tradition Equipment Leasing, LLC. Our mission is to be a valuable partner with privately owned businesses in a mutually beneficial relationship.</p>
                <p>Duane is a graduate of Mankato State University with a Bachelor of Arts degree in Business Administration.</p>
            </div>
            
        </div>
    )
}

function TeamMember (props) {

    return (
        <div className="team-member">
            <img src={props.image} alt={`profile of ${props.name}`} />
            <b>{props.name}</b>
            <span>{props.title}</span>
            <a href={`mailto:${props.email}`}>Email {props.emailName}</a>
        </div>
    )
}

export default About
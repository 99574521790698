import {
    Link
} from "react-router-dom";

import { HashLink as ScrollLink } from 'react-router-hash-link';

import logoV from '../img/logo-blue-big.jpg'

function Nav () {
    return (
        <nav className="nav">
            <Link to="/" className="logo">
                <img src={logoV} alt="logo"/>
            </Link>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <Link to="/inventory">Inventory</Link>
                </li>
                <li>
                    <Link to="/financing">Finance</Link>
                </li>
                <li>
                    <ScrollLink to="/#contact">Contact</ScrollLink>
                </li>
            </ul>
        </nav>
    )
}

export default Nav
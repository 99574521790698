import { useState, useEffect } from 'react'
import {
    Link,
    useParams
} from "react-router-dom";
import StoryblokClient from 'storyblok-js-client/dist/es5/index.es'

function Inventory () {

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [inventory, setInventory] = useState([])

    const { productId } = useParams()
    useEffect(() => {
        
        let Storyblok = new StoryblokClient({
            accessToken: 'rjrSt7TnhclwJBOnbcrnyQtt',
            cache: {
                clear: 'auto',
                type: 'memory'
            }
        })
        
        setIsPageLoading(true)

        Storyblok.getAll("cdn/stories")
            .then((response) => {
                try {
                    const inventory = response.map(item => 
                        item && 
                            item.content && 
                            item.content.component &&
                            item.content.component === "inventoryItem"  ?
                        item :
                        undefined
                    )
                    setInventory(inventory)
                } catch (err) {

                } 
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsPageLoading(false)
            })
    }, [])

    
    // console.log(inventory)
    return (
        <div className="inventory">

            {
                isPageLoading ?
                <div>Loading</div> :
                <div className="container">
                    <h1>Current Inventory:</h1>
                    {
                        inventory && inventory.length > 0 ?
                        inventory.map((item, i) => (<InventoryItem item={item} key={i}/>) ) :
                        <h3>There is no invengory at this time.</h3>
                    }
                </div>
            }
        </div>
    )
}

function InventoryItem (props) {
    if (!props.item || !props.item.content)
        return null
    
    const data = props.item.content

    return (
        <Link className="inventory-item" to={`/inventory/${props.item.id}`}>
            <div className="image-container" style={{
                "background": `url(${data.image})`
            }}></div>
            
            <span className=""><span className="field">Make:</span>{data.make}</span>
            <span className=""><span className="field">Model:</span>{data.model}</span>
            <span className=""><span className="field">Year:</span>{data.year}</span>
            {
                data.mileage && data.mileage !== "NaN" ?
                <span className=""><span className="field">Milage:</span>{data.mileage} miles</span> :
                null
            }
        </Link>
    )
}

export default Inventory